button,
input {
    outline: none !important;
}

.btn {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 12px;
    &.btn-primary {
        background-color: transparent;
        color: $white !important;
        padding-left: 0;
        &:hover,
        &:active,
        &:disabled {
            background-color: transparent;
        }
    }
    &.btn-secondary {
        background-color: transparent;
        color: $secondary !important;
        padding-left: 0;
        &:hover,
        &:active,
        &:disabled {
            background-color: transparent;
        }
    }
}

label {
    color: #5a5a5d;
}
