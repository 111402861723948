@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick-slide {
    height: auto;
    overflow: hidden;
}
.slider-hp {
    padding-bottom: 19px;
    .slick-slider {
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .single-item {
        width: 100%;
        margin: 0 auto;

        > div {
        }
    }

    &.slider-hp-hero {
        .single-item {
            width: 75%;
            margin: 0 auto;
        }
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {
    bottom: 5px;
    li {
        width: 5px;
        height: 5px;
        margin: 0 5px 0 0 !important;
        button {
            width: 5px;
            height: 5px;
            &::before {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                content: "";
                background-color: #ffffff;
                opacity: 1;
            }
        }
        &.slick-active {
            button {
                &::before {
                    opacity: 0.5;
                }
            }
        }
    }
}

.image-carousel {
    overflow: hidden;
    .carousel-text {
        margin-top: 20px;
        h2 {
            font-size: 32px;
            line-height: 34px;
        }
        a {
            font-size: 12px;
        }
    }
}

.content {
    .image-carousel {
        max-height: 380px;
    }
}

.top-dots {
    .slick-dots {
        bottom: 20px;
    }
}

@include media-breakpoint-up(xl) {
    .slick-slide {
        max-height: 215px;
    }

    .slick-dots {
        bottom: 22px;
        li {
            width: 10px !important;
            height: 10px !important;
            margin: 0 5px !important;
            button {
                width: 10px !important;
                height: 10px !important;
                &::before {
                    width: 10px !important;
                    height: 10px !important;
                }
            }
        }
    }
    .slider-hp {
        height: 500px;
    }

    .slick-slide {
        height: auto;
        overflow: hidden;
        max-height: 515px;
    }

    .slider-hp {
        .slick-slider {
            margin: 0 auto;

            img {
                width: 100%;
            }
        }

        .single-item {
            width: 515px;
            margin: 0 auto;
            margin-right: 8px;
            height: 100%;
            > div {
                text-align: center;
            }

            img {
                height: 500px;
                object-fit: cover;
            }
        }

        &.slider-hp-hero {
            padding-bottom: 19px;
            .single-item {
                img {
                    width: 390px;
                    object-fit: contain;
                }
            }
        }
    }

    .image-carousel {
        .carousel-text {
            margin-top: 0;
            height: 500px;
            > div {
                margin: 0 50px;
                position: absolute;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
            h2 {
                font-size: 48px;
                line-height: 52px;
            }
            a {
                font-size: 14px;
            }
        }
    }

    .slider-hp-hero {
        .carousel-text {
            > div {
                margin: 0 94px 0 20px !important;
            }
        }
    }
}
