#container-nav {
    position: fixed;
    height: 100px;
    z-index: 2000;
    top: 0;
}

#main-nav {
    white-space: nowrap;
    background-color: $white;
    height: 100px;
    display: flex;
    .navbar-brand {
        position: absolute;
        background-image: url("../images/erimtan_logo_Tr.svg");
        background-size: 105px 57px;
        width: 105px;
        right: inherit;
        left: 16px;
        transition: left 0.25s ease-out;
        height: 57px;
        &:lang(en) {
            background-image: url("../images/erimtan_logo_En.svg");
        }
    }

    .navbar-toggler {
        border: none;
        z-index: 2100;
        position: relative;
        height: 22px;
        display: inline-block;
        padding: 7px 0 0 10px;
    }

    &.navbar-expand-lg {
        justify-content: space-between;
    }

    > .d-block {
        position: absolute;
        right: 10px;
    }
}
#mainnav {
    display: block;
    position: fixed;
    flex-grow: inherit;
    left: 100%;
    transition: all 0.3s ease-in-out;
    background-color: $primary;
    width: 100%;
    top: 0;
    overflow-y: scroll;
    text-align: center;
    z-index: 1500;
    height: 100%;
    overflow-x: hidden;
    box-shadow: inset 200px 0px 300px 60px rgba(0, 0, 0, 0.12);
    ul {
        width: 100%;
        padding-top: 150px;
        text-align: center;
        li {
            margin-bottom: 20px;
            a {
                color: $white;
            }
        }
    }

    .navbar-brand {
        position: absolute;
        left: 26px;
        top: 22px;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(500%);
    }
    li {
        a {
            font-family: "Noto Serif";
            font-size: 20px;
            color: $white;
            line-height: 34px;
        }
    }
}

#navcheck {
    position: fixed;
    opacity: 0;
}

#icon-navcheck {
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    display: inline-block;
    width: 48px;
    height: 28px;
    cursor: pointer;
    &::before {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        opacity: 0;
        pointer-events: none;
        transition: opacity, background-color 0.5s $timing-function;
    }
    .burger {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 2200;
        width: 22px;
        height: 22px;
        margin: 0;
        padding: 0;
        transition: opacity 0.5s $timing-function;
        &::before,
        .bar,
        &::after {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 3px;
            background: $primary;
            content: "";
            transition: all 0.5s $timing-function;
        }
        .bar {
            top: 44%;
        }
        &::before {
            top: 0;
            transform-origin: top left;
        }
        &::after {
            bottom: 0;
            transform-origin: bottom left;
        }
    }
}

[id="navcheck"]:checked {
    & ~ nav#container-nav {
        #mainnav {
            left: 0;
            box-shadow: -200px 0px 300px 60px rgba(0, 0, 0, 0.12);
        }
        #icon-navcheck {
            &::before {
                opacity: 1;
                pointer-events: auto;
                //background-color: $white;
                transition: all 0.5s $timing-function;
            }
            .burger {
                &::before,
                &::after {
                    width: 141.42% !important; // Pythagore!
                    background-color: $white !important;
                }
                &::before {
                    transform: rotate(45deg) translateY(-50%);
                }
                &::after {
                    transform: rotate(-45deg) translateY(50%);
                }
                .bar {
                    transform: scale(0.1);
                }
            }
        }
    }
    & ~ main#app {
        //& ~ nav#container-nav #main-nav > .navbar-brand {
        position: fixed;
        right: 50%;
        transition: right 0.75s ease-in;
        top: 100px;
        margin-top: 0;
    }

    & ~ nav#container-nav #main-nav > .navbar-brand {
        position: absolute;
        left: -50%;
        transition: left 0.75s ease-in;
    }
}

.social-nav {
    display: inline-block;
    li {
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
        a {
            display: block;
        }
    }
}

@include media-breakpoint-up(xl) {
    //Only Desktop

    #container-nav {
        height: 150px;
    }

    #main-nav {
        height: 150px;
        justify-content: space-between;
        .navbar-brand {
            width: 174px;
            height: 103px;
            background-size: 174px 103px;
        }

        .navbar-toggler {
            display: none;
        }

        .navbar-nav {
            li {
                .nav-link {
                    font-size: 18px;
                    padding-left: 15px;
                }
            }
            &:last-child {
                a {
                    padding-right: 0;
                }
            }
        }
    }

    .social-nav {
        display: block;
    }

    #mainnav {
        position: relative;
        left: auto;
        box-shadow: none;
        background-color: transparent;
        transition: none;
        padding-top: 8px;
        overflow: hidden;
        .navbar-brand {
            display: none;
        }
        ul {
            padding-top: 0;
            li {
                float: left;
                margin-bottom: 0;
                a {
                    color: $primary;
                    font-size: 18px;
                    padding: 8px 0 8px 15px;
                    line-height: 34px;
                }
            }
        }
    }

    #icon-navcheck {
        display: none;
    }
}
