.icon {
    background-repeat: no-repeat;
    display: inline-block;
}

.icon-nav {
    background-image: url("../images/icon-arrow.svg");
    height: 20px;
    width: 20px;
    background-size: cover;
    display: inline-block;
    margin-bottom: -5px;
    margin-right: 7px;
    margin-left: 7px;

    &.icon-prev {
        transform: rotate(180deg);
    }

    &.icon-big {
        height: 35px;
        width: 35px;
        margin-bottom: -11px;
    }
}

.icon-envelope {
    background-image: url("../images/icon-envelope.svg");
    height: 15px;
    width: 20px;
    background-size: cover;
    display: inline-block;
    margin-bottom: -3px;
    margin-right: 7px;
}

.icon-phone {
    background-image: url("../images/icon-phone.svg");
    height: 20px;
    width: 20px;
    background-size: cover;
    display: inline-block;
    margin-bottom: -5px;
    margin-right: 7px;
}

.icon-pdf {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    &:before {
        content: "";
        background-image: url("../images/icon-pdf.svg");
        height: 20px;
        width: 16px;
        background-size: cover;
        float: left;
        margin-right: 10px;
    }
}

.icon-close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 24px;
    display: none;
    z-index: 1500;
    &:before,
    &:after {
        position: absolute;
        left: 24px;
        content: " ";
        height: 21px;
        width: 2px;
        top: -17px;
        background-color: $primary;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}
