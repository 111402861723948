.page-title {
    text-align: center;
    margin: 0 auto;
    padding: 0 36px 19px 36px;
    h1 {
        font-size: 22px;
        margin-top: 15px;
        color: $secondary;
        display: inline-block;
    }
    h2 {
        font-size: 22px;
    }
    &.with-nav {
        margin-bottom: 40px;
        div.d-block {
            margin-top: 10px;
            a {
                display: inline-block;
                &.month-prev {
                    float: none;
                }

                &.month-next {
                    float: none;
                }
            }
        }
    }
}

.content {
    h1 {
        font-size: 30px;
    }
    article {
        margin-bottom: 30px;
        h2 {
            font-size: 20px;
            margin-bottom: 15px;
            color: $secondary;
            span {
                font-size: 15px;
            }
        }
        h3 {
            font-size: 18px;
        }
        p,
        time {
            font-size: 14px;
            line-height: 20px;
            color: $dark-gray;
        }

        .article-video {
            img {
            }
        }
    }
    .col-md-8 {
        padding: 0 36px;
    }
}

.event-detail {
    text-align: center;
    time {
        font-size: 14px;
        color: $secondary;
        margin-bottom: 20px;
    }
    h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }
    h2,
    h2 p {
        font-size: 22px;
        color: $primary;
        margin-bottom: 20px;
    }
    figure {
        margin: 0 auto 15px;
        max-width: 324px;
    }
    h3 {
        font-size: 18px;
        margin-bottom: 10px;
        color: $primary;
    }
    p {
        font-size: 12px;
        color: $secondary;
    }
}

.inline-articles {
    h3 {
        font-size: 32px !important;
        text-align: center;
        margin-bottom: 20px;
    }
}

@include media-breakpoint-up(xl) {
    .page-title {
        h1 {
            font-size: 32px;
            margin-top: 19px;
        }
        h2 {
            font-size: 32px;
        }
        &.with-nav {
            margin-bottom: 80px;
            div.d-block {
                margin-top: -43px;
                a {
                    &.month-prev {
                        float: left;
                    }
                    &.month-next {
                        float: right;
                    }
                    .icon-nav {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .event-detail {
        time {
            font-size: 18px;
            margin-bottom: 20px;
        }
        h1 {
            font-size: 40px;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }
        figure {
            margin-bottom: 15px;
        }
        h3 {
            font-size: 22px;
            margin-bottom: 20px;
        }
        p {
            font-size: 14px;
        }
    }

    .content {
        h1 {
            font-size: 40px;
        }
        .col-md-8 {
            padding: 0 18px;
        }
        article {
            h3 {
                font-size: 20px;
            }
        }
    }

    .inline-articles {
        h3 {
            font-size: 32px;
        }
    }
}
