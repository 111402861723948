.box-nav {
    ul {
        margin-bottom: 0;
        li {
            width: 50%;
            padding: 0 6px 12px 6px;
            display: inline-block;
            &:nth-child(2n) {
                padding-right: 0;
            }
            &:nth-child(2n + 1) {
                padding-left: 0;
            }
            a {
                position: relative;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                padding-top: 100%;
                h3 {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 22px;
                }
            }
        }
    }
}

.homepage {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
}

.double-box {
    color: $white;
    .row {
        > div {
            width: 100%;
        }
    }

    // strong {
    //     color: $white;
    //     @extend .h4;
    // }
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    .double-box {
        .row {
            display: flex;
            align-items: stretch;
            > div {
                width: 50%;
                min-height: 100%;

                &:nth-child(1) {
                    padding-right: 6px;
                }
                &:last-child {
                    padding-left: 6px;
                }
            }
        }
    }

    .box-nav {
        margin-bottom: 12px;
        ul {
            li {
                width: 25%;
                height: 262.5px;
                padding-left: 6px !important;
                padding-right: 6px !important;
                padding-bottom: 0;
                overflow: hidden;
                &:nth-child(1) {
                    padding-left: 0 !important;
                }
                &:last-child {
                    padding-right: 0 !important;
                }

                a {
                    h3 {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}
