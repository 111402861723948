#site-footer {
    height: 44px;
    background-color: transparent;
    padding: 10px 0 20px 0;
    color: $primary;
    p {
        font-size: 10px;
    }
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    #site-footer {
        height: 65px;
        padding: 14px 0 26px 0;
        p {
            font-size: 12px;
        }
    }
}
