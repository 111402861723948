.image-text-list {
    ul {
        li {
            margin-bottom: 20px;
            float: left;
            div {
                float: left;
                width: 100%;
            }
            .limage {
                text-align: center;
                padding-bottom: 10px;
                img {
                    width: 75%;
                }
            }
            h2 {
                color: $secondary;
                font-size: 20px;
            }
            p {
                color: $dark-gray;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.image-text-box-list {
    h4 {
        font-size: 18px;
    }
    ul {
        li {
            width: 186px;
            margin: 0 auto 20px auto;
            a {
                color: $dark-gray;
                .timage {
                    text-align: center;
                    padding-bottom: 10px;
                    img {
                        width: 100%;
                    }
                }
                .btext {
                    p {
                        margin-bottom: 10px;
                        line-height: 20px;
                        time {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

.image-text-big-box-list {
    padding: 0 !important;
    ul {
        text-align: center;
        li {
            width: 239px;
            margin: 0 auto 30px auto;
            position: relative;
            a {
                color: $dark-gray;
                .timage {
                    text-align: center;
                    padding-bottom: 10px;
                    img {
                        width: 100%;
                    }
                }
            }
            .btext {
                p {
                    margin-bottom: 10px;
                    line-height: 20px;
                }
                h3 {
                    font-size: 30px;
                    a {
                        color: $secondary;
                    }
                }
                .btn {
                    text-transform: uppercase;
                }
            }
        }
    }
}

.text-list {
    text-align: center;
    ul {
        li {
            margin-bottom: 20px;
            time {
                color: $secondary;
                margin-bottom: 15px;
            }
            h3 {
                font-size: 18px;
                margin-bottom: 15px;
            }
            h2 {
                font-size: 24px;
                margin-bottom: 15px;
            }

            span {
                color: $primary;
                font-size: 20px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    .image-text-list {
        ul {
            li {
                .limage {
                    width: 37%;
                    padding-right: 16px;
                    img {
                        width: 100%;
                    }
                }
                .rtext {
                    width: 63%;
                }
            }
        }
    }
    .image-text-box-list {
        h4 {
            font-size: 20px;
        }
        ul {
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            li {
                padding: 10px 0;
                margin: inherit;
                margin-right: 43px;
                &:nth-child(4n + 4) {
                    margin-right: 0;
                }
            }
        }
    }
    .image-text-big-box-list {
        ul {
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            li {
                width: 50%;
                padding: 10px 30px 60px 0;
                margin-bottom: 30px;
                .timage {
                    width: 239px;
                    margin: 0 auto;
                }
                .btext {
                    p {
                        //padding-bottom: 60px;
                    }
                    h3 {
                        font-size: 32px;
                    }
                    .bottom-links {
                        position: absolute;
                        bottom: 0;
                        left: -30px;
                        right: 0;
                    }
                }
            }
        }
    }

    .text-list {
        ul {
            li {
                margin-bottom: 20px;
                h3 {
                    font-size: 22px;
                }
                h2 {
                    font-size: 28px;
                }
                span {
                    font-size: 24px;
                }
            }
        }
    }
}
