a {
    color: $black;
    &:hover,
    &:active,
    &:focus,
    &:visited {
        text-decoration: none;
        color: $black;
    }
}

.announcement {
    font-family: "Noto Serif", serif;
    font-size: 18px;
    margin: 30px auto !important;
    h3 {
        font-size: 22px;
        margin-bottom: 10px;
    }
    p {
        color: $secondary;
    }
}

.full-bleed {
    margin-left: -15px;
    margin-right: -15px;
}

.border-none {
    border: none;
}

.simple-nav {
    ul {
        list-style: none;
        padding-left: 0;
        li {
            position: relative;
            a {
                &:hover,
                &:active,
                &:focus,
                &:visited {
                    background-color: transparent;
                }
            }
        }
    }
}

.horizontal-nav {
    ul {
        li {
            display: inline-block;
            &:nth-child(1) {
                margin-left: 0 !important;
                a {
                    padding-left: 0;
                }
            }
            &:last-child {
                margin-right: 0 !important;
                a {
                    padding-right: 0;
                }
            }
        }
    }
}

.pipe-nav {
    text-align: center;
    ul {
        li {
            font-family: "Noto Serif", serif;
            font-size: 20px;
            margin-right: 20px;
            &::after {
                content: "|";
                position: absolute;
                right: -18px;
                color: $primary;
            }
            &:last-child {
                &::after {
                    content: "";
                }
            }
        }
    }
}

.centered-nav {
    float: right;
    position: relative;
    left: -50%;
    text-align: left;
    > ul {
        position: relative;
        left: 48%;
    }
}

.centered-div {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
    > div {
        display: table-cell;
        vertical-align: middle;
        > div {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.vertical-centered-div {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.full-screen {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.object-fit_fill {
    object-fit: fill;
}

.object-fit_contain {
    object-fit: contain;
}

.object-fit_cover {
    object-fit: cover;
}

.object-fit_none {
    object-fit: none;
}

.object-fit_scale-down {
    object-fit: scale-down;
}

.underline {
    text-decoration: underline;
}

.headerline {
    position: relative;
    &::after {
        content: "";
        background-color: $black;
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 56px;
        height: 2px;
    }
}

.grayscale {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

.custom-file-btn {
    position: relative;
    overflow: hidden;
    input[type="file"] {
        position: absolute;
        top: 0px;
        height: 37px;
        width: 100%;
        opacity: 0;
        left: 0;
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@include media-breakpoint-up(md) {
    //Not Mobile
    .two-column-nav {
        ul {
            column-count: 2;
        }
    }
    .two-column {
        column-count: 2;
    }
}

@include media-breakpoint-up(xl) {
    .pipe-nav {
        ul {
            li {
                font-size: 24px;
                margin-right: 20px;
                &::after {
                    right: -18px;
                }
            }
        }
    }

    .announcement {
        h3 {
            font-size: 36px;
        }
    }
}
