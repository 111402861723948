.social {
    margin-top: 50px;
    margin-bottom: 80px;
    span {
        float: left;
        color: $primary;
        margin-top: 14px;
        margin-right: 10px;
    }
    li {
        a {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #dfdfdf;
            margin: 6px;
            text-align: center;
            padding-top: 9px;
            color: $primary;
            display: block;
            &:hover,
            &:active,
            &:focus {
                background-color: #dfdfdf !important;
            }
        }
    }
}

.seperator {
    background-color: $primary;
    display: block;
    width: 35px;
    height: 3px;
}

.seperator-big {
    background-color: $primary;
    display: block;
    width: 50px;
    height: 4px;
}

.play-button {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    margin: 0;
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    .seperator-big {
        width: 70px;
        height: 5px;
    }
}
