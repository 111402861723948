// Fonts
@import "fonts";
// Variables
@import "variables";
// Bootstrap
@import "bootstrap";
// Mixins
@import "mixins";
// Functions
@import "functions";
//Forms
@import "forms";
// Helpers
@import "helpers";
//Includes
@import "homepage";
@import "header";
@import "footer";
@import "icons";
@import "lists";
@import "content";
@import "widgets";

//Libraries
@import "slick";

//Generic Customizations
#app {
    margin-top: 100px;
    right: 0;
    transition: right 0.25s ease-out;
    position: relative;
    top: 0;
}
.gm_info {
    font-size: 10px;
}

.container {
    padding-left: 0;
    padding-right: 0;
}

.container-inner {
    width: 100%;
    margin: 0 auto;
}

.px-mobile {
    padding: 0 36px;
}

a {
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline-style: none;
        color: $primary;
    }
}

.mb-12px {
    margin-bottom: 12px;
}

.mb-30px {
    margin-bottom: 15px;
}

@include media-breakpoint-up(xl) {
    //Only Desktop

    #app {
        margin-top: 150px;
    }

    .container-inner {
        width: 1005px;
    }

    .px-mobile {
        padding: 0;
    }

    .mb-30px {
        margin-bottom: 30px;
    }

    body {
        font-size: 13px;
    }
}
